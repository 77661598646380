<template>
  <div class="purchase" v-if="!onlyModal">
    <!-- <SubscribeHeader :noCloseBtn="noCloseBtn" @onClose="$emit('onClose')" /> -->
    <div v-if="showContent">
      <div class="purchase__app">
        <svg
          width="18"
          height="23"
          viewBox="0 0 18 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_686_4007)">
            <path
              d="M14.8204 12.7917C14.8273 11.9562 15.0477 11.1362 15.4608 10.4099C15.8739 9.68351 16.4658 9.07485 17.1804 8.64173C16.725 7.99798 16.1271 7.46812 15.4333 7.09345C14.7394 6.71878 13.9684 6.50943 13.1804 6.48172C11.4804 6.31172 9.87041 7.48172 9.01042 7.48172C8.15042 7.48172 6.82041 6.48173 5.41041 6.53173C4.48649 6.55669 3.58494 6.82147 2.79426 7.30008C2.00358 7.7787 1.35092 8.4547 0.900399 9.26172C-1.0196 12.6017 0.410404 17.5417 2.2804 20.2617C3.2004 21.5917 4.28041 23.0717 5.72041 23.0217C7.16041 22.9717 7.63041 22.1317 9.29041 22.1317C10.9504 22.1317 11.4304 23.0217 12.8904 22.9917C14.3504 22.9617 15.3204 21.6417 16.2304 20.3217C16.8753 19.3636 17.3835 18.3202 17.7404 17.2217C16.8744 16.8489 16.1365 16.2308 15.6177 15.4436C15.0988 14.6564 14.8216 13.7345 14.8204 12.7917Z"
              fill="white"
            />
            <path
              d="M12.2602 3.67C13.1269 2.65237 13.5548 1.33261 13.4502 0C12.1407 0.130171 10.9293 0.751962 10.0601 1.74001C9.63033 2.2225 9.30105 2.78586 9.09157 3.39713C8.88209 4.0084 8.79662 4.6553 8.84014 5.3C9.49682 5.30751 10.1465 5.16451 10.7393 4.88196C11.3322 4.59941 11.8524 4.18482 12.2602 3.67Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_686_4007">
              <rect width="17.75" height="23" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_686_4010)">
            <path
              d="M2.37002 20.6612L13.37 10.3012L2.26002 0.0211532C1.97413 -0.0207602 1.68255 -0.000228934 1.40536 0.0813339C1.12816 0.162897 0.871937 0.303556 0.654306 0.493626C0.436675 0.683697 0.262804 0.91865 0.144678 1.18235C0.026551 1.44604 -0.0330305 1.73222 -0.0299744 2.02115V18.7212C-0.0351083 19.0181 0.0259247 19.3124 0.148706 19.5827C0.271487 19.8531 0.452933 20.0927 0.679864 20.2842C0.906796 20.4757 1.17353 20.6143 1.46069 20.6899C1.74784 20.7654 2.04822 20.7761 2.34002 20.7212L2.37002 20.6612Z"
              fill="white"
            />
            <path
              d="M17.0696 6.85875L5.05957 1.21875L14.1296 9.61875L17.0696 6.85875Z"
              fill="white"
            />
            <path
              d="M14.1404 10.9883L5.15039 19.4283L17.1604 13.7883L14.1404 10.9883Z"
              fill="white"
            />
            <path
              d="M20.6401 8.52863L18.0501 7.30859L14.8701 10.3086L18.1401 13.3086L20.6401 12.1386C20.9837 11.9771 21.2742 11.7212 21.4777 11.4006C21.6812 11.0801 21.7893 10.7083 21.7893 10.3286C21.7893 9.94895 21.6812 9.57717 21.4777 9.25665C21.2742 8.93614 20.9837 8.68013 20.6401 8.51862V8.52863Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_686_4010">
              <rect width="21.79" height="20.7" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="purchase__rating">
        <svg
          width="112"
          height="16"
          viewBox="0 0 112 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_686_4004)">
            <path
              d="M15.9996 5.55957C15.9783 5.49335 15.9364 5.43571 15.8801 5.39494C15.8237 5.35417 15.7559 5.33243 15.6863 5.33293H10.253L8.33295 0.219564C8.30959 0.155214 8.26702 0.0996751 8.21096 0.0603841C8.1549 0.0210932 8.08809 0 8.01963 0C7.95118 0 7.88437 0.0210932 7.82831 0.0603841C7.77225 0.0996751 7.72968 0.155214 7.70632 0.219564L5.78632 5.33293H0.352967C0.283411 5.33243 0.215477 5.35417 0.159119 5.39494C0.102762 5.43571 0.0609445 5.49335 0.0396532 5.55957C0.0156645 5.62403 0.0130344 5.69453 0.0322476 5.76058C0.0514608 5.82662 0.0914359 5.88473 0.146261 5.92627L4.62624 9.7863L2.70632 15.5596C2.68596 15.6256 2.68663 15.6965 2.70827 15.7621C2.72992 15.8278 2.77143 15.8851 2.82709 15.9261C2.88275 15.9671 2.94977 15.9898 3.0189 15.991C3.08803 15.9923 3.15583 15.972 3.21291 15.9329L8.01963 12.4129L12.8263 15.9329C12.8834 15.972 12.9512 15.9923 13.0203 15.991C13.0894 15.9898 13.1564 15.9671 13.2121 15.9261C13.2678 15.8851 13.3093 15.8278 13.331 15.7621C13.3526 15.6965 13.3533 15.6256 13.3329 15.5596L11.4129 9.7863L15.8929 5.92627C15.9478 5.88473 15.9877 5.82662 16.0069 5.76058C16.0262 5.69453 16.0236 5.62403 15.9996 5.55957Z"
              fill="white"
            />
          </g>
          <g clip-path="url(#clip1_686_4004)">
            <path
              d="M39.9996 5.55957C39.9783 5.49335 39.9364 5.43571 39.8801 5.39494C39.8237 5.35417 39.7559 5.33243 39.6863 5.33293H34.253L32.3329 0.219564C32.3096 0.155214 32.267 0.0996751 32.211 0.0603841C32.1549 0.0210932 32.0881 0 32.0196 0C31.9512 0 31.8844 0.0210932 31.8283 0.0603841C31.7722 0.0996751 31.7297 0.155214 31.7063 0.219564L29.7863 5.33293H24.353C24.2834 5.33243 24.2155 5.35417 24.1591 5.39494C24.1028 5.43571 24.0609 5.49335 24.0397 5.55957C24.0157 5.62403 24.013 5.69453 24.0322 5.76058C24.0515 5.82662 24.0914 5.88473 24.1463 5.92627L28.6262 9.7863L26.7063 15.5596C26.686 15.6256 26.6866 15.6965 26.7083 15.7621C26.7299 15.8278 26.7714 15.8851 26.8271 15.9261C26.8828 15.9671 26.9498 15.9898 27.0189 15.991C27.088 15.9923 27.1558 15.972 27.2129 15.9329L32.0196 12.4129L36.8263 15.9329C36.8834 15.972 36.9512 15.9923 37.0203 15.991C37.0894 15.9898 37.1564 15.9671 37.2121 15.9261C37.2678 15.8851 37.3093 15.8278 37.331 15.7621C37.3526 15.6965 37.3533 15.6256 37.3329 15.5596L35.4129 9.7863L39.8929 5.92627C39.9478 5.88473 39.9877 5.82662 40.0069 5.76058C40.0262 5.69453 40.0236 5.62403 39.9996 5.55957Z"
              fill="white"
            />
          </g>
          <g clip-path="url(#clip2_686_4004)">
            <path
              d="M63.9996 5.55957C63.9783 5.49335 63.9364 5.43571 63.8801 5.39494C63.8237 5.35417 63.7559 5.33243 63.6863 5.33293H58.253L56.3329 0.219564C56.3096 0.155214 56.267 0.0996751 56.211 0.0603841C56.1549 0.0210932 56.0881 0 56.0196 0C55.9512 0 55.8844 0.0210932 55.8283 0.0603841C55.7722 0.0996751 55.7297 0.155214 55.7063 0.219564L53.7863 5.33293H48.353C48.2834 5.33243 48.2155 5.35417 48.1591 5.39494C48.1028 5.43571 48.0609 5.49335 48.0397 5.55957C48.0157 5.62403 48.013 5.69453 48.0322 5.76058C48.0515 5.82662 48.0914 5.88473 48.1463 5.92627L52.6262 9.7863L50.7063 15.5596C50.686 15.6256 50.6866 15.6965 50.7083 15.7621C50.7299 15.8278 50.7714 15.8851 50.8271 15.9261C50.8828 15.9671 50.9498 15.9898 51.0189 15.991C51.088 15.9923 51.1558 15.972 51.2129 15.9329L56.0196 12.4129L60.8263 15.9329C60.8834 15.972 60.9512 15.9923 61.0203 15.991C61.0894 15.9898 61.1564 15.9671 61.2121 15.9261C61.2678 15.8851 61.3093 15.8278 61.331 15.7621C61.3526 15.6965 61.3533 15.6256 61.3329 15.5596L59.4129 9.7863L63.8929 5.92627C63.9478 5.88473 63.9877 5.82662 64.0069 5.76058C64.0262 5.69453 64.0236 5.62403 63.9996 5.55957Z"
              fill="white"
            />
          </g>
          <g clip-path="url(#clip3_686_4004)">
            <path
              d="M87.9996 5.55957C87.9783 5.49335 87.9364 5.43571 87.8801 5.39494C87.8237 5.35417 87.7559 5.33243 87.6863 5.33293H82.253L80.3329 0.219564C80.3096 0.155214 80.267 0.0996751 80.211 0.0603841C80.1549 0.0210932 80.0881 0 80.0196 0C79.9512 0 79.8844 0.0210932 79.8283 0.0603841C79.7722 0.0996751 79.7297 0.155214 79.7063 0.219564L77.7863 5.33293H72.353C72.2834 5.33243 72.2155 5.35417 72.1591 5.39494C72.1028 5.43571 72.0609 5.49335 72.0397 5.55957C72.0157 5.62403 72.013 5.69453 72.0322 5.76058C72.0515 5.82662 72.0914 5.88473 72.1463 5.92627L76.6262 9.7863L74.7063 15.5596C74.686 15.6256 74.6866 15.6965 74.7083 15.7621C74.7299 15.8278 74.7714 15.8851 74.8271 15.9261C74.8828 15.9671 74.9498 15.9898 75.0189 15.991C75.088 15.9923 75.1558 15.972 75.2129 15.9329L80.0196 12.4129L84.8263 15.9329C84.8834 15.972 84.9512 15.9923 85.0203 15.991C85.0894 15.9898 85.1564 15.9671 85.2121 15.9261C85.2678 15.8851 85.3093 15.8278 85.331 15.7621C85.3526 15.6965 85.3533 15.6256 85.3329 15.5596L83.4129 9.7863L87.8929 5.92627C87.9478 5.88473 87.9877 5.82662 88.0069 5.76058C88.0262 5.69453 88.0236 5.62403 87.9996 5.55957Z"
              fill="white"
            />
          </g>
          <g clip-path="url(#clip4_686_4004)">
            <path
              d="M112 5.55957C111.978 5.49335 111.936 5.43571 111.88 5.39494C111.824 5.35417 111.756 5.33243 111.686 5.33293H106.253L104.333 0.219564C104.31 0.155214 104.267 0.0996751 104.211 0.0603841C104.155 0.0210932 104.088 0 104.02 0C103.951 0 103.884 0.0210932 103.828 0.0603841C103.772 0.0996751 103.73 0.155214 103.706 0.219564L101.786 5.33293H96.353C96.2834 5.33243 96.2155 5.35417 96.1591 5.39494C96.1028 5.43571 96.0609 5.49335 96.0397 5.55957C96.0157 5.62403 96.013 5.69453 96.0322 5.76058C96.0515 5.82662 96.0914 5.88473 96.1463 5.92627L100.626 9.7863L98.7063 15.5596C98.686 15.6256 98.6866 15.6965 98.7083 15.7621C98.7299 15.8278 98.7714 15.8851 98.8271 15.9261C98.8828 15.9671 98.9498 15.9898 99.0189 15.991C99.088 15.9923 99.1558 15.972 99.2129 15.9329L104.02 12.4129L108.826 15.9329C108.883 15.972 108.951 15.9923 109.02 15.991C109.089 15.9898 109.156 15.9671 109.212 15.9261C109.268 15.8851 109.309 15.8278 109.331 15.7621C109.353 15.6965 109.353 15.6256 109.333 15.5596L107.413 9.7863L111.893 5.92627C111.948 5.88473 111.988 5.82662 112.007 5.76058C112.026 5.69453 112.024 5.62403 112 5.55957Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_686_4004">
              <rect width="16" height="16" fill="white" />
            </clipPath>
            <clipPath id="clip1_686_4004">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(24)"
              />
            </clipPath>
            <clipPath id="clip2_686_4004">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(48)"
              />
            </clipPath>
            <clipPath id="clip3_686_4004">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(72)"
              />
            </clipPath>
            <clipPath id="clip4_686_4004">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(96)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <h3 class="purchase__title">{{ title }}</h3>
      <ul class="unlock-subscribe__list purchase__list">
        <li
          class="unlock-subscribe__item purchase__item"
          v-for="(item, i) in items"
          :key="i"
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.5 9L10 16L7.5 13.5"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.5 24C18.8513 24 24 18.8513 24 12.5C24 6.14873 18.8513 1 12.5 1C6.14873 1 1 6.14873 1 12.5C1 18.8513 6.14873 24 12.5 24Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span>{{ item }}</span>
        </li>
      </ul>
      <div v-if="isPackageLoading" class="skeleton-loader">
        <a-skeleton-button
          style="width: 327px; height: 92px; margin-top: 32px"
          active
          block
          shape="round"
        />
        <a-skeleton-button
          style="height: 327px; height: 153px; margin-top: 20px"
          block
          active
          shape="round"
        />
        <a-skeleton-button
          style="
            width: 327px;
            height: 92px;
            margin-top: 20px;
            margin-bottom: 32px;
          "
          active
          block
          shape="round"
        />
      </div>
    </div>
    <PackagesCards
      :isPackageLoading="isPackageLoading"
      :onGetPlan="onGetPlan"
      :defaultPackages="defaultPackages"
      :btnText="btnText"
      v-model="currentPackage"
    />
  </div>

  <PaymentsModalDynamic
    :isOpen="isOpen"
    :isEurope="isEurope"
    :packages="packages"
    @close="isOpen = false"
    :currentPackage="currentPackage"
    @onSuccess="onSuccess"
    :vslId="vslId"
    :redirect="redirect"
  />
</template>

<script>
import SubscribeHeader from './SubscribeHeader.vue'
import PackagesCards from './PackagesCards.vue'
import PaymentsModalDynamic from '@/components/subscribe/PaymentsModalDynamic.vue'
import {
  purchaseTypes,
  purchaseComments,
  paymentTypes,
  packagePopular,
  metaEventCustomActions,
  packagePlaces,
} from '@/data/constants'
import { mapActions, mapState } from 'vuex'

import { loadFacebookPixel, initFacebook } from '@/utils/facebookConfig'
import { getCountryByIp } from '@/services/other.service'
import { currency } from '@/data/constants'

export default {
  name: 'PurchaseDynamic',
  components: {
    SubscribeHeader,
    PaymentsModalDynamic,
    PackagesCards,
  },
  props: {
    packages: {
      type: Array,
      default: null,
    },
    noCloseBtn: {
      type: Boolean,
      default: false,
    },
    showContent: {
      type: Boolean,
      default: false,
    },
    items: Array,
    initialOpen: {
      type: Boolean,
      default: false,
    },
    initialPackage: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: null,
    },
    onlyModal: {
      type: Boolean,
      default: false,
    },
    vslId: {
      type: Number,
      default: null,
    },
    redirect: {
      type: String,
      default: null,
    },
    btnText: {
      type: String,
      default: 'Get My Plan',
    },
  },
  emits: ['onSuccess', 'onClose'],
  data() {
    return {
      purchaseComments,
      purchaseTypes,
      currentPackage: '',
      isOpen: false,
      paymentTypes,
      packagePopular,
      isEurope: false,
    }
  },
  computed: {
    ...mapState('subscription', ['action']),
    ...mapState('checkout', ['checkoutType']),
    ...mapState(['token']),
    getYearly() {
      return (
        (this.packages && this.getPackageByCondition((i) => i.months === 12)) ||
        {}
      )
    },
    isYearlyPackage() {
      return this.currentPackage === this.purchaseTypes.YEARLY
    },
    isMonthlyPackage() {
      return this.currentPackage === this.purchaseTypes.MONTHLY
    },
    defaultPackages() {
      if (this.packages.some((i) => i.currency === currency.EUR))
        return this.isEurope
          ? this.packages.filter(
              (i) =>
                i.provider === this.paymentTypes.STRIPE &&
                i.currency === currency.EUR
            )
          : this.packages.filter(
              (i) =>
                i.provider === this.paymentTypes.STRIPE &&
                i.currency === currency.USD
            )

      return this.packages.filter(
        (i) => i.provider === this.paymentTypes.STRIPE
      )
    },
  },
  watch: {
    defaultPackages: {
      immediate: true,
      handler() {
        if (this.currentPackage) return

        const isBestValue = this.defaultPackages.find(
          (i) => i.tags == 'Best Value' || i.tags == 'Most Popular'
        )
        if (isBestValue) {
          this.currentPackage = this.getPackageValue(isBestValue)
        } else {
          if (this.defaultPackages.length) {
            this.currentPackage = this.getPackageValue(this.defaultPackages[0])
          }
        }
        this.setActivePackage(this.currentPackage)
      },
    },
  },
  created() {
    if (this.initialPackage) {
      this.currentPackage = this.initialPackage
    }

    this.isOpen = this.initialOpen

    getCountryByIp()
      .then((res) => {
        this.isEurope = res.timezone.split('/')[0] === 'Europe'
        this.fetchPackages()
      })
      .catch((err) => {
        console.log(err)
        this.fetchPackages()
      })
  },
  async mounted() {
    await initFacebook()
    window.fbAsyncInit()
    if (this.action === metaEventCustomActions.ON_UNLOCK_BUTTON) {
      this.loadFacebookPixel('BuyIntent')
    } else if (this.action === metaEventCustomActions.ON_QUIZ_FINISHED) {
      this.loadFacebookPixel('InformIntent')
    } else if (this.action === metaEventCustomActions.ON_LOCKED_CLICKED) {
      fbq('trackCustom', 'LockedIntent')
    }
  },
  methods: {
    ...mapActions('packageModule', ['fetchPackage', 'setActivePackage']),
    ...mapActions('checkout', ['fetchCheckoutById']),
    loadFacebookPixel,
    onGetPlan() {
      if ((this.vslId && !this.token) || this.$route.query.vsl_id) {
        this.isOpen = true
        return
      }
      if (!this.token || this.token === undefined) {
        this.$router.push({
          name: 'Login',
          query: {
            redirect: `${window.location.pathname}?from=auth&plan=${this.currentPackage}`,
          },
        })
      } else this.isOpen = true
    },
    fetchPackages() {
      if (!this.checkoutType) {
        this.fetchPackage({
          place: packagePlaces.AFTER_LOCKED_CONTENT,
          currency: this.isEurope ? currency.EUR : currency.USD,
        })
      }
    },
    getPackageByCondition(condition) {
      let p
      if (this.isEurope) {
        p = this.packages.find(
          (i) => condition(i) && i.currency === currency.EUR
        )
      }
      if (!p)
        p = this.packages.find(
          (i) => condition(i) && i.currency === currency.USD
        )
      return p
    },
    onSuccess() {
      this.isOpen = false
      this.$emit('onSuccess')
    },
    getPackageValue(p) {
      if (p.months === 12) return this.purchaseTypes.YEARLY
      if (p.months === 1) return this.purchaseTypes.MONTHLY
      else return this.purchaseTypes.LIFETIME
    },
  },
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}
.skeleton-loader {
  max-width: 327px;
  margin: 0 auto;

  .ant-skeleton-element .ant-skeleton-input {
    height: 100%;
  }

  .ant-skeleton-element .ant-skeleton-button {
    height: 100%;
  }
}
.purchase {
  color: var(--color-white);
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 80px 0;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 104px;

  @media (max-width: 767px) {
    padding: 20px 0;
    margin-bottom: 56px;
  }

  &__payment {
    &-title {
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: var(--color-white);
      font-weight: 400;
    }

    &__wrapper {
      margin: 32px 0 24px;
      display: flex;
      justify-content: space-between; //change to space-between when paypal will be active
      & > * + * {
        margin-left: 17px;
      }
    }

    &__btn {
      border: 1px solid rgba(#ffffff, 0.1);
      border-radius: 12px;
      background: inherit;
      height: 72px;
      padding: 0 27px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;

      &:hover {
        opacity: 0.8;
      }

      &.active {
        border: 1px solid #ffffff;
      }

      &-title {
        margin: 8px 0;
        display: block;
        font-size: 16px;
        line-height: 22px;
        color: var(--color-white);
        font-weight: 400;
      }
    }

    &__paypal {
      &--btn {
        font-weight: 400;
        background: var(--color-white);
        border-radius: 58px;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #134e5e;
        height: 52px;
        width: 100%;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

        & > svg {
          margin-right: 4px;
          margin-top: 3px;
        }

        &:hover {
          opacity: 0.8;
        }
      }
      &--text {
        font-weight: 400;
        color: var(--color-white);
        text-transform: uppercase;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        margin: 16px 0 24px;
      }

      .ant-spin-container::after {
        border-radius: 58px;
      }
    }
  }

  &__app {
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      &:last-child {
        margin-left: 26px;
      }
    }
  }

  &__rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;

    &-text {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      margin: 8px 0 0;
    }
  }

  &__title {
    font-size: 24px;
    line-height: 33px;
    font-weight: 500;
    color: var(--color-white);
    text-align: center;
    margin: 48px 0 31px;
  }

  &__item {
    font-weight: 500;
    line-height: 19px;
  }

  &__list {
    row-gap: 16px;
    max-width: 402px;
    margin: 0 auto 28px;
  }

  &__total {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    margin: 36px 0 16px;
  }

  &__cancel {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 36px;
  }

  .p-card {
    background: rgba(#ffffff, 0.1);
    border-radius: 12px;
  }

  &__card {
    padding: 16px;
    display: grid;
    align-items: center;
    margin-bottom: 24px;

    &-head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 23px;
    }

    &-number {
      margin-bottom: 16px;
    }

    &-detail {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 17px;
    }

    &-info {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin: 24px auto 16px;
      max-width: 198px;
      text-align: center;
    }
  }

  &__input {
    border: 1px solid rgba(#ffffff, 0.1);
    border-radius: 58px;
    height: 52px;
    text-align: center;
    padding: 17px 10px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    width: 100%;
  }

  &__btn {
    min-width: 295px;
    width: 100%;
    margin: 24px auto 8px;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &__cancel-info {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 8px;
  }

  &__rating-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin: 28px 0 36px;
  }

  &__rating-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    & > svg + svg {
      margin-top: 13px;
    }

    &__name {
      font-size: 14px;
      line-height: 19px;
      font-weight: 500;
      margin: 20px 0 8px;

      &--big {
        font-size: 24px;
        line-height: 33px;
      }
    }

    &__label {
      font-size: 14px;
      line-height: 19px;
      font-weight: 500;
      opacity: 0.5;
      margin: 0;
    }

    &--app {
      display: flex;
      align-items: center;
      margin-bottom: 13px;
      & > svg:last-child {
        margin-left: 26px;
      }
    }
  }

  &__trial-work {
    padding: 24px 50px 24px 16px;

    &.extra-space {
      padding: 24px 24px 24px 16px;
    }

    &__title {
      font-size: 24px;
      line-height: 33px;
      font-weight: 700;
      margin-bottom: 27px;
      text-align: center;

      &.normal {
        font-weight: 400;
      }
    }

    &__body {
      display: flex;
    }

    &__icons {
      background: linear-gradient(
        180deg,
        rgba(#ffffff, 0.3) 0%,
        rgba(255, 255, 255, 0) 112.9%
      );
      border-radius: 46px 46px 4px 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 12px 9px;
      margin-right: 24px;

      & > svg + svg {
        margin-top: 64px;
      }
    }

    &__items {
      margin-top: 10px;
      display: grid;
      row-gap: 20px;
    }

    &__item {
      &--title {
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        margin: 0;
      }

      &--text {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin: 4px 0 0;
      }
    }
  }

  &__guarantee {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    align-items: center;
    padding: 20px 24px 24px;
    text-align: center;

    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      margin: 17px 0 8px;
    }

    &__desc {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      opacity: 0.5;
      margin: 0;
    }

    &__text {
      font-weight: 500;
      margin: 17px 0 0;
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__p {
    text-align: center;
    color: var(--color-white);

    &-m {
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
    }

    &-l {
      font-weight: 500;
      font-size: 48px;
      line-height: 66px;
    }

    &-s {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
    }

    &-xs {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  }

  &__svg-wrapper {
    display: flex;
    justify-content: center;
    margin: 28px 0 12px;
  }

  &__feel-better {
    margin: 5px 0 4px;
  }

  &__wrapper {
    width: 335px;
    margin: 0 auto;
  }

  &__comments {
    display: flex;
    margin: 20px 0 48px;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
  }

  &__comment {
    border-radius: 12px;
    background: rgba(#ffffff, 0.1);
    padding: 24px;
    width: 264px;
    min-height: 314px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.middle {
      padding-right: 20px;
    }

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: var(--color-white);
    }

    &__author {
      display: flex;

      &-right {
        margin-left: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }

      &-name {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: var(--color-white);
      }
    }
  }

  &__note {
    margin: 48px auto;
    max-width: 825px;
  }
}

.payments {
  .ant-modal {
    max-width: 887px;
  }

  .ant-modal-content {
    background: #134e5e;
    border-radius: 12px;
  }

  .ant-modal-body {
    padding: 0;
    position: relative;
  }

  &__close {
    position: absolute;
    top: 17px;
    right: 17px;
    background: inherit;
    border: none;
    cursor: pointer;
  }
}

.strikethrough {
  text-decoration: line-through;
  color: rgba(#ffffff, 0.5);
}

.mb-48 {
  margin-bottom: 48px;
}
</style>
